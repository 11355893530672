import React, { useEffect, useState } from "react";

import * as S from "../styles";
import { REQ_STATUS, USER_STATUS } from "../../../util/constants";
import { USERS } from "../../../api/USERS";
import { Description, FH, Label, Subtitle, Title } from "../../main-styles";
import moment from "moment-timezone";
import UserStatus from "../../../components/UserStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faLockOpen,
  faPaperPlane,
} from "@fortawesome/pro-solid-svg-icons";
function Users() {
  const [state, setState] = useState({
    data: [],
    status: REQ_STATUS.LOADING,
    page: 0,
    perPage: 50,
    needle: "",
    hasMore: true,
    selectedUser: null,
  });
  const { data, status, page, perPage, needle, hasMore, selectedUser } = state;

  useEffect(() => {
    // fetch data
    loadData();
  }, [page, perPage]);

  async function loadData() {
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    let users = await USERS.get.all(page, perPage, needle);
    setState((s) => ({
      ...s,
      data: users,
      status: REQ_STATUS.SUCCESS,
      hasMore: users.length === perPage,
    }));
  }

  function selectUser(user) {
    setState((s) => ({ ...s, selectedUser: user }));
  }

  function next() {
    if (!hasMore) return;
    setState((s) => ({ ...s, page: page + 1 }));
  }

  function prev() {
    if (page === 0) return;
    setState((s) => ({ ...s, page: page - 1 }));
  }

  return (
    <S.TicketsGrid maxed={true}>
      <S.TicketView maxed={true} style={{ gridColumn: "span 6" }}>
        <S.TitleContainer style={{ borderBottom: "1px solid #e9e9e9" }}>
          <Title>Usuários</Title>
        </S.TitleContainer>
        <table
          className="table table-striped"
          style={{ border: "1px solid #e9e9e9" }}
        >
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              {/* <th>Empresa</th> */}
              <th style={{ width: 200, textAlign: 'center' }}>Tickets abertos</th>
              <th style={{ width: 200 , textAlign: 'center'}}>Status</th>
              <th style={{ width: 200 , textAlign: 'center'}}>Último login</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((user) => (
              <tr key={user.id} onClick={() => selectUser(user)}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                {/* <td>{user.company_name ? user.company_name : "-"}</td> */}
                <td style={{textAlign: 'center'}}>{user.open_tickets}</td>
                <td>
                  <UserStatus status={user.status} />
                </td>
                <td style={{ textAlign: 'center'}}>
                  {user.last_seen
                    ? moment(user.last_seen).format("DD/MM/YYYY HH:mm")
                    : '-'}
                </td>
                <td style={{ textAlign: "center" }}>
                  {/* <FH style={{ justifyContent: "flex-end", paddingRight: 20 }}>
                    {user.status === USER_STATUS.PREACTIVE && (
                      <div>
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </div>
                    )}
                    {user.status !== USER_STATUS.BLOCKED && (
                      <div>
                        <FontAwesomeIcon icon={faLock} color={"red"} />
                      </div>
                    )}
                    {user.status === USER_STATUS.BLOCKED && (
                      <div>
                        <FontAwesomeIcon icon={faLockOpen} color={"green"} />
                      </div>
                    )}
                  </FH> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </S.TicketView>
      <S.TicketDetails style={{ gridColumn: "span 2" }}>
        {selectedUser && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 30,
              textAlign: "center",
            }}
          >
            <img
              style={{ width: 200, height: 200, borderRadius: 100 }}
              src={selectedUser.thumbnail}
            />
            <Title>{selectedUser.name}</Title>
            <Subtitle>{selectedUser.email}</Subtitle>
            <Description>{selectedUser.company_name}</Description>
            <UserStatus status={selectedUser.status} />
            <Label>
              Desde:{" "}
              {moment(selectedUser.created_at).format("DD/MM/YYYY HH:mm")}
            </Label>

            <Label>
              Último login:{" "}
              {selectedUser.last_seen
                ? moment(selectedUser.last_seen).format("DD/MM/YYYY HH:mm")
                : moment(selectedUser.created_at).format("DD/MM/YYYY HH:mm")}
            </Label>
            <Label>
                Tickets abertos: {selectedUser.open_tickets}
            </Label>
            <Label>
                Tickets: {selectedUser.tickets}
            </Label>
          </div>
        )}
      </S.TicketDetails>
    </S.TicketsGrid>
  );
}

export default Users;
