export default {
  translation: {
    // Mesmo valor usado nas configurações (defaultNS)
    home: {
      header: {
        faq: "FAQ",
        "start-free": "Começar gratuitamente",
        pricing: "Preços",
      },
      hero: {
        "nao-e-necessario-cartao-de-credito":
          "Não é necessário cartão de crédito",
        "resolva-chamados-com-rapidez-e-eficiencia":
          "Resolva chamados com rapidez e eficiência",
        "resolva-chamados-com-rapidez-e-eficiencia":
          "Resolva chamados com rapidez e eficiência",
        "organize-seu-suporte-melhorando-e-acompanhando-cada-atendimento":
          "Organize seu suporte melhorando e acompanhando cada atendimento",
        "comece-agora-gratuitamente": "Comece agora gratuitamente",
        "o-que-fazemos": "O que fazemos",
        "somos-um-helpdesk-de-baixo-custo-para-empresas-que-desejam-fornecer-um-excelente-suporte-ao-cliente-sem-gastar-uma-fortuna-em-um-sistema-de-suporte":
          "Somos um helpdesk de baixo custo para empresas que desejam fornecer um excelente suporte ao cliente sem gastar uma fortuna em um sistema de suporte",
        "integracao-com-chatgpt": "Integração com ChatGPT",
        "utilize-a-inteligencia-artificial-para-garantir-que-seus-clientes-recebam-respostas-rapidas-e-precisas":
          "Utilize a inteligência artificial para garantir que seus clientes recebam respostas rápidas e precisas",
      },
      faq: {
        faq: "FAQ",
        questions: {
          "o-que-e-um-sistema-de-help-desk": "O que é um sistema de help desk",
          "como-funciona-a-precificacao": "Como funciona a precificação",
          "o-que-e-um-agente": "O que é um agente?",
          "e-se-minha-equipe-precisar-de-mais-agentes-no-futuro":
            "E se minha equipe precisar de mais agentes no futuro?",
          "posso-testar-o-help-desk-app-antes-de-comprar":
            "Posso testar o Help-Desk.app antes de comprar?",
          "e-possivel-personalizar-o-help-desk-app-com-a-identidade-visual-da-minha-empresa":
            "É possível personalizar o Help-Desk.app com a identidade visual da minha empresa?",
          "existe-algum-tipo-de-relatorio-ou-painel-de-analise-disponivel":
            "Existe algum tipo de relatório ou painel de análise disponível?",
          "o-help-desk-app-oferece-alguma-ferramenta-de-base-de-conhecimento-ou-faq":
            "O Help-Desk.app oferece alguma ferramenta de base de conhecimento ou FAQ?",
          "voces-tem-integracao-com-inteligencia-artificial":
            "Vocês tem integração com Inteligência Artificial?",
          "outros-sistemas-cobram-por-ticket-voces-tambem":
            "Outros sistemas cobram por ticket, vocês também?",
          "voces-cobram-por-pesquisa-de-satisfacao":
            "Vocês cobram por pesquisa de satisfação?",
          "e-possivel-criar-um-ticket-automaticamente-quando-receber-um-email":
            "É possível criar um ticket automaticamente por email?",
        },
        answers: {
          "um-sistema-de-help-desk-e-uma-ferramenta":
            "Um sistema de help desk é uma ferramenta que permite que as empresas gerenciem e respondam a solicitações de suporte de seus clientes.",
          "como-funciona-a-precificacao":
            "Você pagará uma taxa mensal fixa com base no número de agentes que você tem em sua equipe de suporte.",
          "o-que-e-um-agente":
            "Um agente é um membro da sua equipe de suporte que pode responder a solicitações de suporte de seus clientes.",
          "e-se-minha-equipe-precisar-de-mais-agentes-no-futuro":
            "Você pode adicionar ou remover agentes conforme necessário. Os planos são flexíveis e a cobrança será ajustada de acordo com a quantidade de agentes ativos no mês.",
          "posso-testar-o-help-desk-app-antes-de-comprar":
            "Sim, você pode testar o Help-Desk.app gratuitamente por 14 dias.",
          "e-possivel-personalizar-o-help-desk-app-com-a-identidade-visual-da-minha-empresa":
            "Sim! Você pode customizar cores, inserir o logo da sua empresa e até criar um portal de suporte com a cara do seu negócio.",
          "existe-algum-tipo-de-relatorio-ou-painel-de-analise-disponivel":
            "Sim, o Help-Desk.app oferece relatórios de produtividade, tempo de resposta, satisfação do cliente e outros indicadores importantes para gestão do suporte.",
          "o-help-desk-app-oferece-alguma-ferramenta-de-base-de-conhecimento-ou-faq":
            "Sim. Há uma funcionalidade de base de conhecimento integrada que permite criar artigos e tutoriais, para que seus clientes encontrem soluções de forma mais rápida e independente.",
          "voces-tem-integracao-com-inteligencia-artificial":
            "Sim, o Help-Desk.app possui integração com ChatGPT, e estamos sempre de olho em novas tecnologias para melhorar a experiência do usuário.",
          "outros-sistemas-cobram-por-ticket-voces-tambem":
            "Não, o Help-Desk.app cobra apenas por agente, não por ticket.",
          "voces-cobram-por-pesquisa-de-satisfacao":
            "Não, a pesquisa de satisfação é gratuita para todos os planos.",
          "e-possivel-criar-um-ticket-automaticamente-quando-receber-um-email":
            "Sim, você pode configurar o Help-Desk.app para criar um ticket automaticamente quando receber um email em um endereço específico.",
        },
      },
      cta: {
        "experimente-sem-compromisso-e-veja-como-":
          "Experimente sem compromisso e veja como podemos transformar o suporte ao\ncliente na sua empresa.",
        "comece-sua-avaliacao-gratuita-de-14-dias":
          "Comece sua avaliação gratuita de 14 dias",
      },
      pricing: {
        precificacao: "Precificação",
        gratuito: "Gratuito",
        price: "Grátis",
        "ate-4-agentes": "Até 4 agentes",
        escolher: "Escolher",
        "pro-plan": "Plano Pro",
        "mais-escolhido": "Mais escolhido",
        "r12-por-agente": "R$12 por agente",
        "por-mes": "por mês",
        "enterprise-plan": "Plano Enterprise",
        customizado: "Customizado",
        "muitos-agentes": "Muitos agentes?",
        "solicitar-contato": "Solicitar contato",
        "recursos": "Recursos",
        "agentes": "Agentes",
        "assistente-de-respostas-por-ia": "Assistente de resposta por IA",
        "resumo-de-chamados-por-ia": "Resumo de chamados por IA",
        "pesquisa-de-satisfacao-csat": "Pesquisa de satisfação (CSAT)",
        "tickets-ilimitados": "Tickets ilimitados",
        "contatos-ilimitados": "Contatos ilimitados",
        "empresas-ilimitadas": "Empresas ilimitadas",
        "grupos-ilimitados": "Grupos ilimitados",
        "abertura-de-chamado-por-email": "Abertura de chamados por email",
        "painel-de-suporte-para-abertura-de-chama": "Painel de suporte para abertura de chamados",
        "base-de-conhecimento": "Base de conhecimento",
        "relatorios-de-chamados": "Relatórios de suporte",
        "ate-4": "Até 4",
        "ilimitados": "Ilimitados",
      },

      footer: {
        companyname: "Help-Desk.app - Sistema de chamados de suporte",
        copyright: "2025 HDAPP Sistemas Ltda.",
        cnpj: "CNPJ: 58.420.116/0001-60",
        address: "R XV DE NOVEMBRO, 556 - CONJ 1406 - CENTRO - CURITIBA - PR",
        email: "contato@help-desk.app",
      },
    },
    "new-workspace": {
      "sua-empresa": "sua_empresa",
      "help-desk-app-novo-workspace": "Help-Desk.app - Novo workspace",
      "enviamos-um-email-para": "Enviamos um email para {{email}}",
      "novo-workspace": "Novo workspace",
      "vamos-configurar-o-workspace-de-sua-empr":
        "Vamos configurar o workspace de sua empresa. Escolha um nome para ele:", 
        "ja-existe-um-workspace-com-esse-nome": "Já existe um workspace com esse nome",
        "email": "Email",
        "senha": "Senha",
        "confirmacao-de-senha": "Confirmação de senha",
        "concordo-com-os": "Concordo com os",
        "termos-de-uso": "termos de uso",
        "and": "e",
        "politica-de-privacidade": "política de privacidade",
        "create-workspace": "Criar workspace",
        "ja-tem-uma-conta": "Já tem uma conta?",
        "clique-aqui": "clique aqui",
        "o-nome-do-workspace-nao-e-valido": "O nome do workspace não é válido",
        "voce-precisa-concordar-com-os-termos-de-uso-e-politica-de-privacidade": "Você precisa concordar com os termos de uso e política de privacidade",
        "a-senha-deve-ter-no-minimo-6-caracteres": "A senha deve ter no mínimo 6 caracteres",
        "as-senhas-nao-conferem": "As senhas não conferem",
        "email-invalido": "Email inválido",
        "o-nome-do-workspace-deve-ter-no-minimo-3-caracteres": "O nome do workspace deve ter no mínimo 3 caracteres",
        "verifique-seu-email": "Verifique seu email",
        "enviamos-um-email-para": "Enviamos um email para {{email}}",
        "erro-ao-criar-workspace": "Erro ao criar workspace",
        "creating-wait": "Criando... Aguarde...",
    }

  },
};
