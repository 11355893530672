import React, { useState } from "react";

import * as S from "../styles";
import { REQ_STATUS } from "../../../util/constants";

function Companies() {
  const [state, setState] = useState({
    data: [],
    status: REQ_STATUS.LOADING,
    page: 0,
    perPage: 50,
    needle: "",
  });
  const { data, status, page, perPage, needle } = state;

  return (
    <S.TicketsGrid maxed={true}>
      <S.TicketView
        maxed={true}
        style={{ gridColumn: "span 5" }}
      ></S.TicketView>
      <S.TicketDetails style={{ gridColumn: "span 3" }}></S.TicketDetails>
    </S.TicketsGrid>
  );
}

export default Companies;
